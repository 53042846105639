import React, {useState} from "react";
import {Button, Alert} from "reactstrap";
import Highlight from "../components/Highlight";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../components/Loading";
import {useParams} from "react-router-dom";
import {message, Tag} from "antd";
import axios from "axios"

export const PublisherInfoComponent = () => {

  const [messageApi, contextHolder] = message.useMessage();

  const showError = (msg) => {
    messageApi.error(msg);
  };
  const audience = "https://dev.dinz.pl"
  const routeParams = useParams();
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });

  const {
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();

  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callApi();
  };

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callApi();
  };

  const callApi = async () => {
    try {
      const token = await getAccessTokenSilently();

      const response = await axios.get(`https://dev.dinz.pl/api/notification/${routeParams.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }
      );

      const responseData = await response.data;

      setState({
        ...state,
        showResult: true,
        apiMessage: responseData,
      });
    } catch (error) {
      showError(error.message);
    }
  };

  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };

  return (
      <>
        <div className="mb-5">
          {contextHolder}
          {state.error === "consent_required" && (
              <Alert color="warning">
                You need to{" "}
                <a
                    href="#/"
                    class="alert-link"
                    onClick={(e) => handle(e, handleConsent)}
                >
                  consent to get access to users api
                </a>
              </Alert>
          )}

          {state.error === "login_required" && (
              <Alert color="warning">
                You need to{" "}
                <a
                    href="#/"
                    class="alert-link"
                    onClick={(e) => handle(e, handleLoginAgain)}
                >
                  log in again
                </a>
              </Alert>
          )}

          <h1>Publisher API</h1>
          <p className="lead">
            <Tag>{routeParams.id}</Tag>
          </p>

          <Button
              color="primary"
              className="mt-5"
              onClick={callApi}
              disabled={!audience}
          >
            Ping API
          </Button>
        </div>

        <div className="result-block-container">
          {state.showResult && (
              <div className="result-block" data-testid="api-result">
                <h6 className="muted">Result</h6>
                <Highlight>
                  <span>{JSON.stringify(state.apiMessage, null, 2)}</span>
                </Highlight>
              </div>
          )}
        </div>
      </>
  );
};

export default withAuthenticationRequired(PublisherInfoComponent, {
  onRedirecting: () => <Loading/>,
});
