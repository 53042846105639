import React, {useEffect, useState} from "react";
import {Router, Route, Switch} from "react-router-dom";
import {Container} from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import ExternalApi from "./views/ExternalApi";
import {useAuth0} from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import {
  getFireBasePushNotificationToken,
  onMessageListener
} from "./utils/firebase";
import {Button, message, Result} from "antd";
import PublisherInfo from "./views/PublisherInfo";

initFontAwesome();

const App = () => {
  const {isLoading, error} = useAuth0();
  const [messageApi, contextHolder] = message.useMessage();
  const [isTokenFound, setTokenFound] = useState(false);

  useEffect(() => {
    async function firebaseInitToken() {
      await getFireBasePushNotificationToken(setTokenFound);
    }

    firebaseInitToken();
  }, []);

  if (error) {
    return <Result
        status="500"
        title="500"
        subTitle={error.message}
        extra={<Button href={"/"} type="primary">Back Home</Button>}
    />;
  }

  if (isLoading) {
    return <Loading/>;
  }

  onMessageListener().then(payload => {
    messageApi.info(payload.notification.body, 15000);
    if (isTokenFound) {
      console.log(payload);
    }
  }).catch(err => messageApi.error("Ops."));

  return (
      <Router history={history}>
        <div id="app" className="d-flex flex-column h-100">
          <NavBar debug={{token: isTokenFound}}/>
          <Container className="flex-grow-1 mt-5">
            {contextHolder}
            <Switch>
              <Route path="/" exact component={Home}/>
              <Route path="/p/:id" component={PublisherInfo}/>
              <Route path="/s/:id" component={PublisherInfo}/>
              <Route path="/profile" component={Profile}/>
              <Route path="/external-api" component={ExternalApi}/>
            </Switch>
          </Container>
          <Footer/>
        </div>
      </Router>
  );
};

export default App;
