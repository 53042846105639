// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBPIm7NhvFbvlfOQxxnui_pVqJs8qsQQts",
  authDomain: "dinz-d2313.firebaseapp.com",
  projectId: "dinz-d2313",
  storageBucket: "dinz-d2313.appspot.com",
  messagingSenderId: "926602772281",
  appId: "1:926602772281:web:036aceb9a9065d304032e8",
  measurementId: "G-ZEDF80DDF6"
};

// Initialize Firebase
const firebaseApp  = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getFireBasePushNotificationToken = (setTokenFound) => {
  return getToken(messaging, {vapidKey: 'BH8h_iD-q8MCXE96IBMkExkh-7CO1ZD9WzkoXsjSSAh3kv5hL1-3BsEysLmEQrwnhXXCLNC7ijlnG3TiJ51W-2k'}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      setTokenFound(currentToken);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });