import React from "react";

import logo from "../assets/logo.svg";
import {Flex, Space, message, Button} from "antd";
import {useAuth0} from "@auth0/auth0-react";

const Hero = ({role}) => {
    const [messageApi, contextHolder] = message.useMessage();
    const {user, isAuthenticated} = useAuth0();

    const info = () => {
        messageApi.info('Hello,' + (isAuthenticated ? user.dinz_roles[0] : "Guest"));
    };
    return (<div className="text-center hero my-5">
        <img className="mb-3 app-logo" src={logo} alt="React logo" width="120"/>
        {isAuthenticated && <h1 className="mb-4">{user.dinz_roles[0]}</h1> }
        <Flex justify={"center"} align={"center"} gap={"middle"}>
            {contextHolder}
            {user && user.dinz_roles[0] === "ROLE_SUBSCRIBER" && (<Space direction={"vertical"}>
                <Button size={"large"} type="primary" onClick={info}>
                    Subscribe
                </Button>
                <Button size={"large"} type="default" onClick={info}>
                    Add table
                </Button>
                <Button size={"large"} type="link" onClick={info}>
                    Remove table
                </Button>
            </Space>)}
            {user && user.dinz_roles[0] === "ROLE_PUBLISHER" && (<Space direction={"vertical"}>
                <Button size={"large"} type="primary" onClick={info}>
                    Notify Subscribers
                </Button>
            </Space>)}
            {!isAuthenticated && (<Space direction={"vertical"}>
                <Button size={"large"} type="primary" onClick={info}>
                    Notify Subscribers (Once)
                </Button>
            </Space>)}
        </Flex>
    </div>);
}

export default Hero;
